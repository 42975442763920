<template>
    <div class="ph_page">
      <div class="ph_select">
        <van-field
          readonly
          clickable
          label="主体"
          :value="value"
          placeholder="选择主体"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            value-key="name"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </div>
      <div class="ph_switch">
        <van-switch v-model="isContainTest" size="20px" @change="checkChange"/><span style="font-size: 14px;">包含测试数据</span>
      </div>
      <div class="ph_tabs">
        <van-tabs type="card" color="#1989fa" @click="tabChange">
          <div class="selectDate" v-if="tabsValue == 1">
            <van-field
              readonly
              clickable
              label="选择日期"
              :value="dateValue"
              placeholder="选择日期"
              @click="showTime = true"
            />
            <van-popup v-model="showTime" round position="bottom">
              <van-datetime-picker
                type="date"
                title="选择日期"
                v-model="defaultDate"
                :min-date="minDate"
                :max-date="maxDate"
                @cancel="showTime = false"
                @confirm="onConfirmTime"
              />
            </van-popup>
          </div>
          <van-tab title="按日期统计" class="van-tabs">
            <van-row class="row-title">
              <van-col span="6">商户名称</van-col>
              <van-col span="6">日期</van-col>
              <van-col span="2">状态</van-col>
              <van-col span="4">订单数</van-col>
              <van-col span="6">金额</van-col>
            </van-row>
            <van-row v-for="(item,index) in list" :key="index" class="row-row" :style="{backgroundColor:index%2 === 1?'#ecf5ff':''}">
              <van-col span="6">{{ item.thirdMchName }}</van-col>
              <van-col span="6">{{ item.datestr }}</van-col>
              <van-col span="2" v-if="item.status === 1" style="color:#ff0033">失败</van-col>
              <van-col span="2" v-else style="color:#00cc00">成功</van-col>
              <van-col span="4">{{ item.orderCount }}</van-col>
              <van-col span="6">{{ item.money }}</van-col>
            </van-row>
            <div class="nodata" v-if="list.length === 0">暂无数据</div>
          </van-tab>
          <van-tab title="按小时统计">
            <van-row class="row-title">            
              <van-col span="6">商户名称</van-col>
              <van-col span="6">时间</van-col>
              <van-col span="2">状态</van-col>
              <van-col span="4">订单数</van-col>
              <van-col span="6">金额</van-col>
            </van-row>
            <van-row v-for="(item,index) in list2" :key="index" class="row-row" :style="{backgroundColor:index%2 === 1?'#ecf5ff':''}">
              <van-col span="6">{{ item.thirdMchName }}</van-col>
              <van-col span="6">{{ item.datestr.substr(11,2) + '时'}}</van-col>
              <van-col span="2" v-if="item.status === 1" style="color:#ff0033">失败</van-col>
              <van-col span="2" v-else style="color:#00cc00">成功</van-col>
              <van-col span="4">{{ item.orderCount }}</van-col>
              <van-col span="6">{{ item.money }}</van-col>
            </van-row>
            <div class="nodata" v-if="list2.length === 0">暂无数据</div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </template>
  
  <script>
  import { getDateList, getHourList } from '@/api/index'
  export default {
    name: 'mobilePage',
    data() {
      return {
        value: '', // 主体
        dateValue: '', // 日期
        curIndex: 0, // 主体的序号
        minDate: new Date(1970, 1, 1),
        maxDate: new Date(),
        defaultDate: new Date(),
        showPicker: false,
        showTime: false,
        columns: [],
        list: [],
        list2: [],
        type: '1',
        tabsValue: 0,
        isContainTest: false
      }
    },
    created () {
      this.columns = this.$store.state.options
      this.value = this.columns[0].name
      this.dateValue = this.formatDate(new Date())
      this.getDate(this.curIndex)
    },
    mounted () {
      console.log(this.$store.state)
    },
    methods: {
      getDate (index) {
        var host = this.columns[index]
        var except_domain = host.domain
        if (this.isContainTest) {
          except_domain = ''
        }
        this.list = []
        getDateList(host.api, except_domain).then(res=>{
          this.list = res.data
        })
      },
      getHour (index) {
        var host = this.columns[index]
        var except_domain = host.domain
        if (this.isContainTest) {
          except_domain = ''
        }
        this.list2 = []
        getHourList(host.api, except_domain, this.dateValue).then(res=>{
          this.list2 = res.data
        })
      },
      checkChange () {
        console.log('this.isContainTest 是否包含测试数据', this.isContainTest)
        if (this.tabsValue ===  1) {
          this.getHour(this.curIndex)
        } else {
          this.getDate(this.curIndex)
        }
      },
      tabChange (val) {
        this.tabsValue = val
        if (this.tabsValue ===  1) {
          this.getHour(this.curIndex)
        } else {
          this.getDate(this.curIndex)
        }
      },
      // 主体下拉选择确定
      onConfirm(value) {
        this.value = value.name
        this.type = value.id
        for (let i = 0; i < this.columns.length; i++) {
          if(this.columns[i].id === value.id) {
            this.curIndex = i
          }
        }
        this.showPicker = false
        if (this.tabsValue ===  1) {
          this.getHour(this.curIndex)
        } else {
          this.getDate(this.curIndex)
        }
      },
      // 日期选择
      onConfirmTime(value) {
        this.dateValue = this.formatDate(value)
        this.showTime  = false
        this.getHour(this.curIndex)
      },
      // 时间格式化
      formatDate(date) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        if ( month < 10 ) {
          month = '0' + month
        }
        let day = date.getDate()
        if ( day < 10 ) {
          day = '0' + day
        }
        return year + '-' + month + '-' + day
      }
    },
  }
  </script>
  <style scoped>
  ::v-deep .van-tabs__nav--card {
    box-sizing: border-box;
    height: 30px;
    margin: 0 8px;
    border: 1px solid #ee0a24;
    border-radius: 2px;
  }

  ::v-deep .row-title[data-v-500203e1] {   
    margin-top: 20px;
    margin-right: 8px;
    margin-bottom: 0px;
    margin-left: 8px;
    background-color: #c6e2ff;
    color: #333;
    font-weight: bold;
    padding: 10px 0;
  }

  ::v-deep .row-row[data-v-500203e1] {
    background-color: #fff;
    margin: 16px 8px 0 8px;
    padding: 10px 0;
  }

    .ph_page{
      padding: 2px;
    }
    .ph_select{
      display: flex;
      justify-content: center;
      border-radius: 5px;
    }
    .ph_switch {      
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-radius: 5px;
      margin-top: 10px;
      margin-right: 8px;
      margin-left: 8px;
    }
    .ph_tabs{
      margin: 10px 0;
      padding: 20px 0;
      background-color: #fff;
      border-radius: 5px;
    }
    .selectDate {
      margin-top: 16px;
      margin-right: 8px;
      margin-bottom: 16px;
      margin-left: 8px;
    }

    .selectDate .van-cell{
      padding: 10px;
      background-color:#f7f8fa !important;
    }
    .row-title{
      margin: 20px 16px 0 16px;
      background-color:#c6e2ff;
      color:#333;
      font-weight: bold;
      padding: 10px 0;
    }
    .row-row{
      background-color: #fff;
      margin: 16px 16px 0 16px;
      padding: 10px 0;
    }
    .nodata {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin-top: 20px;
    }
  </style>
  