import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import vant from 'vant'
import 'vant/lib/index.css'
import { Picker } from 'vant'
import axios from 'axios'
import { VueJsonp  } from 'vue-jsonp'
import store from './store/index'

Vue.prototype.axios = axios
Vue.use(VueJsonp)
Vue.use(Picker)
Vue.use(ElementUI)
Vue.use(vant)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
