<template>
  <div id="app">
    <pcPage v-if="type === false"></pcPage>
    <mobilePage v-else></mobilePage>
  </div>
</template>

<script>
import pcPage from './components/pcPage.vue'
import mobilePage from './components/mobilePage.vue';

export default {
  name: 'App',
  components: {
    pcPage,
    mobilePage
  },
  data () {
    return {
      type:false
    }
  },
  mounted(){
    if (this._isMobile()) {
      // 手机端
      this.type = true
      // this.$router.replace("/mobilePage")
    } else {
      // pc端
      this.type = false
      // this.$router.replace("/pcPage")
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      )
      return flag;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}
body{
  background-color: #f7f8fa;
}
</style>
