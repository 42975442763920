import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      options:[
        {
          id: '1',
          name: "巧菲悦2024",
          api:'api_qfy2024',
          domain:'www.qiaofeiyue.xyz',
          interfaceUrl: "https://www.qiaofeiyue.xyz/"
        },
        // {
        //   id: '1',
        //   name: "坚莫商贸",
        //   api:'api_jmsm',
        //   domain:'www.jmmaoyi.club',
        //   interfaceUrl: "https://www.jmmaoyi.club/"
        // },
        // {
        //   id: '2',
        //   name: "企微云端", 云服务器未续费
        //   api:'api_qwyd',
        //   domain:'www.qiweiyun.top',
        //   interfaceUrl: "https://www.qiweiyun.top/"
        // },
        // {
        //   id: '3',
        //   name: "海娱文化",
        //   api:'api_hywh',
        //   domain:'www.haiwuwh.cn',
        //   interfaceUrl: "https://www.haiwuwh.cn/"
        // },
        // {
        //   id: '32',
        //   name: "涌瑞贸易",
        //   api:'api_yrmy',
        //   domain:'www.yongrui.cloud',
        //   interfaceUrl: "https://www.yongrui.cloud/"
        // },
        // {
        //   id: '4',
        //   name: "音惠科技",
        //   api:'api_yhkj',  // 从猛禽物流主体上直接改过来的
        //   domain:'yinhuikj.shop',
        //   interfaceUrl: "https://yinhuikj.shop/"
        // },
        // {
        //   id: '42',
        //   name: "福州鼎晟机电",
        //   api:'api_fzdcjd',  
        //   domain:'cluster-pay.com',
        //   interfaceUrl: "https://cluster-pay.com/"
        // },
        // {
        //   id: '5',
        //   name: "巧菲悦",
        //   api:'api_qfy',
        //   domain:'www.qiaofeiyue.club',
        //   interfaceUrl: "https://www.qiaofeiyue.club/"
        // },
        // {
        //   id: '6',
        //   name: "海南禧玮严选",
        //   api:'api_hnsm',
        //   domain:'www.hainanshangmao.shop',
        //   interfaceUrl: "https://www.hainanshangmao.shop/"
        // },
        // {
        //   id: '7',
        //   name: "郢晟潮",
        //   api:'api_ycc',
        //   domain:'www.yingcc158.cn',
        //   interfaceUrl: "https://www.yingcc158.cn/"
        // }
      ]
    },
    mutations: {
      updateOptions (state, option) {
        state.options = option
      },
    },
    actions: {

    }
})