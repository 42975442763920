<template>
  <div class="pcPage">
    <div class="pcPage_select">
      <el-select v-model="value" placeholder="请选择主体" @change="selectType">
        <el-option
          v-for="item, index  in options"
          :key="index"
          :label="item.name"
          :value="index"
          class="input-width"
          >
        </el-option>
      </el-select>
      <el-checkbox v-model="isContainTest" @change="checkChange">包含测试数据</el-checkbox>
    </div>
    <div class="pcPage_tabs pcPage_select">
      <el-radio-group v-model="tabPosition" style="margin:20px 0;" @change="tabChange(tabPosition)">
        <el-radio-button label="1">按日期统计</el-radio-button>
        <el-radio-button label="2">按小时统计</el-radio-button>
      </el-radio-group>
      <div class="date_picker" v-if="tabPosition === '2'">
        <el-date-picker
          v-model="dateValue"
          type="date"
          @change="selectDate"
          placeholder="选择日期">
        </el-date-picker>
      </div>
    </div>
    <div v-if="tabPosition === '1'">
      <el-table
        :data="tableData"
        :header-cell-style="{background:'#c6e2ff',color:'#333'}"
        border
        :row-class-name="tableClss"
        style="width: 100%">        
        <el-table-column prop="thirdMchName" label="商户名称" align="center"/>
        <el-table-column prop="datestr" label="日期" align="center"/>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 1" style="color:#ff0033">失败</span>
            <span v-else style="color:#00cc00">成功</span>
          </template>
        </el-table-column>
        <el-table-column  prop="orderCount" label="订单数" align="center"/>
        <el-table-column  prop="money" label="金额" align="center"/>
      </el-table>
    </div>
    <div v-if="tabPosition === '2'">
      <el-table
        :data="tableData"
        :header-cell-style="{background:'#c6e2ff',color:'#333'}"
        border
        :row-class-name="tableClss"
        style="width: 100%">
        <el-table-column prop="thirdMchName" label="商户名称" align="center"/>
        <el-table-column prop="datestr" label="日期" align="center"/>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 1" style="color:#ff0033">失败</span>
            <span v-else style="color:#00cc00">成功</span>
          </template>
        </el-table-column>
        <el-table-column  prop="orderCount" label="订单数" align="center"/>
        <el-table-column  prop="money" label="金额" align="center"/>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getDateList, getHourList } from '@/api/index'
export default {
  name: 'pcPage',
  data () {
    return {
      value: '',
      curIndex: 0, // 主体的序号
      dateValue: new Date().toUTCString(),
      options: [],
      tabPosition: '1',
      tableData : [],
      isContainTest: false
    }
  },
  created () {
    this.options = this.$store.state.options
    this.value = this.options[0].name
    this.dateValue = this.formatDate(new Date())
    this.getDate(this.curIndex)
  },
  methods: {
    getDate(index) {
      var host = this.options[index]
      var except_domain = host.domain
      if (this.isContainTest) {
        except_domain = ''
      }
      this.tableData = []
      getDateList(host.api, except_domain).then(res=>{
        this.tableData = res.data
      })
    },
    getHour(index) {
      var host = this.options[index]
      var except_domain = host.domain
      if (this.isContainTest) {
        except_domain = ''
      }
      this.tableData = []
      getHourList(host.api, except_domain, this.dateValue).then(res=>{
        this.tableData = res.data
      })
    },
    checkChange () {
      if (this.tabPosition === '1') {
        this.getDate(this.curIndex)
      } else {
        this.getHour(this.curIndex)
      }
    },
    // 选择主体
    selectType (val) {
      this.curIndex = val
      if (this.tabPosition === '1') {
        this.getDate(val)
      } else {
        this.getHour(val)
      }
    },
    // tab切换
    tabChange (val) {
      this.tabPosition = val
      if (this.tabPosition === '1') {
        this.getDate(this.curIndex)
      } else {
        this.getHour(this.curIndex)
      }
    },
    // 日期选择
    selectDate(val) {
      this.dateValue = this.formatDate(val)
      console.log('dateValue', this.dateValue)
      this.getHour(this.curIndex)
    },
    // 标准时间转换年月日
    formatDate(date) {
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day)
    },
    // 表格各行变色
    tableClss ({rowIndex}) {
      if (rowIndex % 2 === 1) {
        return 'even-row'
      } else {
        return ''
      }
    },
  }
}
</script>

<style scoped>
  .el-table /deep/ .even-row {
      background-color: #ecf5ff !important;
      border-color: #ecf5ff;
  }
  .pcPage {
    margin: 10px 20px;
  }
  .pcPage_select{
    display: flex;
  }
  .date_picker{
    margin-left: 20px
  }
  .pcPage_tabs{
    display: flex;
    align-items: center;
  }
  .input-width{
    display: block;
  }
</style>
