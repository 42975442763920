import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode:"history",
  routes: [
    // pc端
    {
      path: '/',
      name: 'pcPage',
      component: ()=>import("../components/pcPage"),
    },
    // 手机端
    {
      path: '/',
      name: 'mobilePage',
      component: ()=>import("../components/mobilePage"),
    },
  ]
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
