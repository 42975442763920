import axios from 'axios'

const restHttp = axios.create({
    timeout: 1000 * 30,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    baseURL: '', // 这里的配置要和config跨域的 /api要不保持一致
})

// 按日期统计
export async function getDateList (api,domain) {
    const rsp = await restHttp.get(api+`/app-api/api/operate/order/summaryByDate?domain=`+domain)
    return rsp.data
}



// 按小时统计
export async function getHourList (api, domain, date) {
    const rsp = await restHttp.get(api+`/app-api/api/operate/order/summaryByHour?domain=`+domain+`&date=` + date)
    return rsp.data
}
